/** Dependencies */
import { createRef, useEffect } from 'react';
import { Chart } from 'smart-webcomponents-react/chart';

/** Helpers */
import { getPicto } from './../../helpers/pictos';
import { isNullOrUndefined } from './../../helpers/functions';

/** JSON */
import i18n from './../../assets/json/i18n.json';

/** SCSS */
import './InsightsCompareStackedColumnChart.scss';

function InsightsCompareStackedColumnChart( props )
{
  const {
    id,
    datas,
    series,
    dataField,
    flip,
    xAxisPosition,
    orientation,
    xAxisLabelVisible,
    customXaxis,
    maxValue,
    highlightedValues
  } = props;

  // Define ref
  const chartRef = createRef( null );

  // Define positive and negitive colors
  const gridLinesColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey3' ).trim();
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();

  /** Get label from i18n json file */
  const rangesLabels = i18n.fr.topRangeLabels;

  /** Define seriesGroups */
  // get series
  const currentSeries = !isNullOrUndefined( series?.current ) ? series.current : [];
  const compareSeries = !isNullOrUndefined( series?.compare ) ? series.compare : [];
  
  // set global params
  const globalParams = 
  {
    type: 'stackedcolumn',
    columnsGapPercent: compareSeries.length > 0 ? 35 : 15,
    orientation: orientation,
    valueAxis: {
      visible: true,
      axisSize: 30,
      minValue: 0,
      maxValue: maxValue ? maxValue : NaN,
      flip: flip?.valueAxis !== undefined ? flip.valueAxis : false,
      gridLines: {
        dashStyle: '4,4',
        color: gridLinesColor
      },
      labels: {
        class: 'values-axis-labels',
        offset: {
          y: -1
        }
      },
      tickMarks: {
        visible: false
      },
      formatFunction: value => value >= 1000 ? value / 1000 + 'k' : value
    }
  };

  // update compare global params
  const compareGlobalParams = {
    ...globalParams,
    valueAxis: {
      ...globalParams.valueAxis,
      ...{
        visible: false,
        axisSize: 0
      }
    }
  }

  // define seriesGroups
  const seriesGroups = currentSeries.length > 0 && compareSeries.length > 0 ? 
    [ { ...compareGlobalParams, series: [ ...compareSeries ] }, { ...globalParams, series: [ ...currentSeries ] } ]
    : [ { ...globalParams, series: [ ...currentSeries ] } ];

  // Define settings for chart
  const settings = {
    caption: '',
    description: '',
    showLegend: false,
    animation: 'none',
    showBorderLine: false,
    showToolTips: true,
    backgroundColor: 'white',
    columnSeriesOverlap: true,
    toolTipFormatFunction: ( value, itemIndex, serie, group, xAxisValue, xAxis ) => 
      ( serie.dataField.startsWith( 'current' ) ? xAxisValue : datas[itemIndex].compareXaxisCaption )
      + ' : ' 
      + ( 
        rangesLabels[ serie.dataField.replace(/(current|compare)-/g, '' ) ] !== undefined ? 
          rangesLabels[ serie.dataField.replace(/(current|compare)-/g, '' ) ] 
          : serie.dataField.replace(/(current|compare)-/g, '' ) 
      ) 
      + ' : ' 
      +  Intl.NumberFormat('fr-FR').format( value ),
    padding: {
      left: 0,
      top: 6,
      right: 0,
      bottom: 0
    },
    titlePadding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    dataSource: datas,
    xAxis: {
      dataField: dataField,
      position: xAxisPosition !== undefined ? xAxisPosition : 'bottom',
      tickMarks: {
        visible: false
      },
      labels: {
        visible: xAxisLabelVisible !== undefined ? xAxisLabelVisible : true,
        class: 'x-axis-labels',
        offset: {
          y: flip?.valueAxis !== undefined ? -5 : 5
        }
      },
      gridLines: {
        color: gridLinesColor
      },
      valuesOnTicks: false
    },
    seriesGroups: seriesGroups
  };

  useEffect( () => 
  {
    if( 
      highlightedValues !== undefined 
      && highlightedValues !== null
    ){
      chartRef.current.showToolTip(
        highlightedValues.groupIndex,
        series.findIndex( serie => serie.dataField === highlightedValues.serieValue ),
        highlightedValues.itemIndex
      );
    }
  }, [ JSON.stringify( highlightedValues ) ]);

  return(
    <div className="insights-stacked-column-chart">

      { customXaxis !== undefined ? 
        <ul className="custom-xaxis">
          { datas.map( elem => elem.xaxis ).map( ( xAxis, index ) => 
            <li key={ index }>{ getPicto( xAxis, { size: "1rem", fill: grey2Color, weight: "bold" } ) }</li>
          )}          
        </ul>
        : null
      }
      
      <Chart
        {...settings}
        ref={ chartRef }
				id = { id + '-chart' }
			/>
    </div>
  )
}

export default InsightsCompareStackedColumnChart;