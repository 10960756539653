/** JSON */
import i18n from './../assets/json/i18n.json';

/** Helpers */
import { isNullOrUndefined } from './functions';

/**
 * Return string which will be used for chart tooltip
 * @param {Mixed} value 
 * @param {Int} itemIndex 
 * @param {Object} serie 
 * @param {Object} group 
 * @param {String} xAxisValue 
 * @param {Object} xAxis 
 * @param {Array} datas 
 * @param {String} context 
 * @returns {String}
 */
export const toolTipFormatFunction = ( value, itemIndex, serie, group, xAxisValue, xAxis, datas, context ) =>
{
  let tooltipContent = '';

  /** Get label from i18n json file */
  const rangesLabels = i18n.fr.topRangeLabels;

  if( typeof context === 'string' && !isNullOrUndefined( datas[itemIndex] ) )
  {
    switch ( context )
    {
      case 'keywords':
        tooltipContent = 
          ( serie.dataField.indexOf( 'current' ) > -1 ? xAxisValue : datas[itemIndex].compareXaxisCaption )
          + ' : ' 
          + ( 
            rangesLabels[ serie.dataField.replace(/(current|compare)-/g, '' ) ] !== undefined ? 
              rangesLabels[ serie.dataField.replace(/(current|compare)-/g, '' ) ] 
              : serie.dataField.replace(/(current|compare)-/g, '' ) 
          )
          + ' : ' 
          + Intl.NumberFormat( 
              'fr-FR', 
              serie.dataField.indexOf( '-ctr' ) > -1 ? 
                { style: 'percent', maximumSignificantDigits: 3 } 
                : { maximumSignificantDigits: 3 }           
          ).format( value );
        break;
    }
  }

  return tooltipContent;
}