/** Dependencies */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import moment from 'moment';

/** Components */
import Loader from './../../Loader/Loader';
import InsightsCompareValues from './../../Insights/InsightsCompareValues';
import InsightsListValues from './../../Insights/InsightsListValues';
import InsightsCompareColumnChart from './../../Insights/InsightsCompareColumnChart';
import InsightsCompareStackedColumnChart from './../../Insights/InsightsCompareStackedColumnChart';
import InsightsCompareLineChart from './../../Insights/InsightsCompareLineChart';
import InsightsCompareStepLineChart from './../../Insights/InsightsCompareStepLineChart';
import InsightsCompareAreaChart from './../../Insights/InsightsCompareAreaChart';
import InsightsCompareMultiLineChart from './../../Insights/InsightsCompareMultiLineChart';

/** Helpers */
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';
import { getDateFromPeriod, getPeriodsDiffFromDate } from'./../../../helpers/dates'
import { deepMergeObj, isNullOrUndefined } from './../../../helpers/functions';
import { formatDatasForChart } from './../../../helpers/datas';
import { getInstanceDatas } from './../../../helpers/instance';

/** SCSS */
import './MarketWidget.scss';
import './CompareValuesChart.scss';

function CompareValuesChart( props )
{
  const {
    id,
    title,
    pictoTitle,
    typeChart,
    series,
    serviceName,
    params,
    constantValue,
    formatResultFct,
    aggregateDatasForInsight,
    transparent,
    flip,
    xAxisPosition,
    orientation,
    caption,
    customXaxis,
    xAxisLabelVisible,
    maxValue,
    unit,
    mouseOverCallbackFct,
    highlightedValues,
    totalDisplay
  } = props;

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Init State */
  const [datas, setDatas] = useState( {} );
  const [currentDatas, setCurrentDatas] = useState( [] );
  const [compareDatas, setCompareDatas] = useState( [] );
  const [datasLoaded, setDatasLoaded] = useState( null );
  const [currentParams, setCurrentParams] = useState( null );
  const [graphOpacity, setGraphOpacity] = useState( false );

  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue('--color-blue').trim();

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  // get instance infos from url
  const { clientID, location, device } = useParams();

  // get city from data user
  let city = null;
  if( clientID !== undefined && userDatas !== null )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( 
      devLoc => devLoc.countryValue === location 
    )[0].cityValue;

  /** Load Datas */
  useEffect( () => 
  { 
    // reset datas
    setDatas( {} );
    setCurrentDatas( [] );
    setCompareDatas( [] );

    if( 
      currentParams !== null 
      && (
        (
          currentParams?.webservice?.where?.keywords !== undefined 
          && Array.isArray( currentParams.webservice.where.keywords ) 
          && currentParams.webservice.where.keywords.length > 0
        ) || (
          currentParams?.webservice?.where?.keywords !== undefined 
          && typeof currentParams.webservice.where.keywords === 'string' 
          && currentParams.webservice.where.keywords === 'followedKeywords' 
        ) || (
          currentParams?.webservice?.where?.keywords === undefined 
        )
      )
    ){
      // set loading datas
      let loadingDatas = {};
      if( !isNullOrUndefined( currentParams?.currentPeriod ) )
      {
        // current period
        loadingDatas.currentDates = { 
          datas: getDateFromPeriod( currentParams.currentPeriod, null, 'YYYYMMDD' ), 
          function: 'setCurrentDatas' 
        };

        // compare period
        if( 
          !isNullOrUndefined( currentParams?.comparePeriod )
          && currentParams.comparePeriod !== 'none' 
        )
          loadingDatas.compareDates = { 
            datas: getDateFromPeriod( currentParams.currentPeriod, currentParams.comparePeriod, 'YYYYMMDD' ), 
            function: 'setCompareDatas' 
          };
      }

      // call webservice for each period
      Object.keys( loadingDatas ).forEach( key => 
      {
        // get week diff
        const { weekDiff } = getPeriodsDiffFromDate( 
          moment( loadingDatas[key].datas.startDate ).startOf( 'week' ), 
          moment( loadingDatas[key].datas.endDate ).endOf( 'week' )
        );

        // load current datas
        callWebservice( 
          id,
          id + '-' + key,
          serviceName,
          dispatch,
          clientID,
          deepMergeObj(
            {
              period: {
                startDate: loadingDatas[key].datas.startDate,
                endDate: loadingDatas[key].datas.endDate
              },
              where: {
                devices: [ device ],
                locations: [ location + '|' + city ],
                dataVizViewType: weekDiff > 12 ? 'month' : 'week' 
              }
            },
            currentParams.webservice
          ),
          { function: loadingDatas[key].function }
        );
      });
    }    

  }, [ 
    JSON.stringify( currentParams ),
    clientID, 
    location, 
    device,
    id
  ]);

  /** Set datas when loaded */
  useEffect( () => 
  {
    if( 
      currentDatas
      && (
        currentDatas.length > 0 
        || Object.keys( currentDatas ).length > 0 
      )
    ){
      // clone datas
      const cloneDatas = { ...datas };

      /** Format and set insights datas */
      if( aggregateDatasForInsight )
        cloneDatas.insight = aggregateDatasForInsight( 
          currentDatas, 
          compareDatas, 
          flip?.valueAxis !== undefined ? flip.valueAxis : null
        );

      /** Format and set chart datas */
      // get dates from current period
      const currentDates = getDateFromPeriod( currentParams.currentPeriod, null, 'YYYYMMDD' );
      
      // get weekdiff from current dates
      const { weekDiff } = getPeriodsDiffFromDate(
        moment( currentDates.startDate ).startOf( 'week' ),
        moment( currentDates.endDate ).endOf( 'week' )
      );

      // format with function
      cloneDatas.chart = formatDatasForChart( 
        currentDatas, 
        params.comparePeriod !== 'none' ? 
          compareDatas 
          : Array.isArray( currentDatas ) ?
            currentDatas.map( elem => ( { x: elem.x, y: null } ) )
            : null, 
        weekDiff > 12 ? 'month' : 'week' 
      );

      // set weekdiff if volume case in week context
      if( id === 'search-volume' && weekDiff <= 12 )
        setGraphOpacity( true );
      else
        setGraphOpacity( false );
    
      // set datas
      setDatas( cloneDatas );

      // reset dataLoaded value
      setDatasLoaded( false );
    }

  }, [ datasLoaded ] );

  useEffect( () => params !== undefined ? setCurrentParams( params ) : {} ,[ JSON.stringify( params ) ] );
  
  return(
    <div className={ graphOpacity ? 'widget compare-values-chart less-opacity' : 'widget compare-values-chart' } id={ id }>

      {/* Loader */}
      <Loader 
        loaderID={ id } 
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setCurrentDatas: results => setCurrentDatas( formatResultFct !== undefined ? formatResultFct( results ) : results ),
          setCompareDatas: results => setCompareDatas( formatResultFct !== undefined ? formatResultFct( results ) : results )
        }}
        globalCallBack={ () => setDatasLoaded( true ) } 
      />

      {/* Title / Picto */}
      <h4 className='picto-title'>
        { pictoTitle }
        { title }
      </h4>

      {/* Insights Compare Values */}
      {
        datas?.insight !== undefined
        && datas?.insight?.current !== undefined
        && datas?.insight?.compare !== undefined
        && datas?.insight?.variation !== undefined ?
          <InsightsCompareValues
            currentValue = { datas.insight.current }
            compareValue = { datas.insight.compare }
            variation = { datas.insight.variation }
            notation = { 'compact' }
          />
        : null
      }

      {/* Insights List Values */}
      {
        datas?.insight !== undefined
        && Array.isArray( datas.insight )
        && datas.insight.length > 0 ?
          <InsightsListValues
            values = { datas.insight }
            unit = { unit }
            totalDisplay={ totalDisplay === true ? totalDisplay : false }
          />
        : null
      }

      {/* Captions  */}
      {
        datas?.chart !== undefined
        && datas.chart.length > 0
        && caption !== undefined
        && Array.isArray( caption )
        && caption.length > 0 ?
          <ul className='captions'>{ caption.map( ( caption, index ) => <li key={ index }>{ caption }</li>) }</ul>
        : null
      }

      {/* Chart */}
      {
        typeChart === 'line' && datas?.chart !== undefined && datas.chart.length > 0 ?
          <InsightsCompareLineChart
            id = { id }
            datas = { datas.chart }
            dataField = 'xaxis'            
            flip = { flip }
            xAxisLabelVisible = { xAxisLabelVisible }
            xAxisPosition = { xAxisPosition }
          />   
        : typeChart === 'multi-line' && datas?.chart !== undefined && datas.chart.length > 0 ?
          <InsightsCompareMultiLineChart
            id = { id }
            datas = { datas.chart }
            series = { typeof series === 'function' ? series( datas.chart, { symbolType: 'circle' } ) : series }
            dataField = 'xaxis'
            flip = { flip }
            xAxisLabelVisible = { xAxisLabelVisible }
            xAxisPosition = { xAxisPosition }
            mouseOverCallbackFct = { details => typeof mouseOverCallbackFct === 'function' ? mouseOverCallbackFct( details ) : null }
            highlightedValues = { highlightedValues }
          />
        : typeChart === 'column'  && datas?.chart !== undefined && datas.chart.length > 0 ? 
          <InsightsCompareColumnChart
            id = { id }
            datas = { datas.chart }
            dataField = 'xaxis'
            transparent = { transparent }
            xAxisLabelVisible = { xAxisLabelVisible }
          />   
        : typeChart === 'stackedColumn'  && datas?.chart !== undefined && datas.chart.length > 0 ?
          <InsightsCompareStackedColumnChart
            id = { id }
            datas = { datas.chart }
            series = { typeof series === 'function' ? series( datas.chart ) : series }
            dataField = 'xaxis'
            flip = { flip }
            xAxisPosition = { xAxisPosition }
            xAxisLabelVisible = { xAxisLabelVisible }
            orientation = { orientation }
            customXaxis = { customXaxis }
            maxValue = { maxValue }
            highlightedValues = { highlightedValues }
          />
        : typeChart === 'stepline'  && datas?.chart !== undefined && datas.chart.length > 0 ?
          <InsightsCompareStepLineChart
            id = { id }
            datas = { datas.chart }
            dataField = 'xaxis'
            flip = { flip }
            xAxisPosition = { xAxisPosition }
            constantValue = { constantValue }
          />
        : typeChart === 'area'  && datas?.chart !== undefined && datas.chart.length > 0 ?
          <InsightsCompareAreaChart
            id = { id }
            datas = { datas.chart }
            series = { typeof series === 'function' ? series( datas.chart ) : series }
            dataField = 'xaxis'
            flip = { flip }
            xAxisPosition = { xAxisPosition }
            xAxisLabelVisible = { xAxisLabelVisible }
            orientation = { orientation }
            customXaxis = { customXaxis }
            maxValue = { maxValue }
            highlightedValues = { highlightedValues }
          />   
        : <div className='nodata'>Pas de donnée disponible</div>
      }
    </div>
  );
}

export default CompareValuesChart;