/** Dependencies */
import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import WindowButton from './../../WindowButton/WindowButton';

/** Redux */
import store from './../../../store';

/** Helpers */
import { getPicto } from './../../../helpers/pictos';

/** SCSS */
import './GridCellPictos.scss';

function GridCellPictos( props )
{
  const{
    id,
    index,
    pageSize,
    value
  } = props;

  /** Define colors */
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'pictures-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );

  // define max pictos
  const maxPictoToDisp = 3;

  // get valueMe and valueOthers
  let valueMe = value?.valueMe ? value.valueMe : null;
  let valueOthers = value?.valueOthers ? value.valueOthers : null;
  
  // merge me and others
  if( valueMe !== null )
    valueMe = valueMe.split( '|' );

  if( valueOthers !== null )
    valueOthers = valueOthers.split( '|' );

  if( Array.isArray( valueMe ) && Array.isArray( valueOthers ) )
    valueOthers = valueOthers.filter( elem => !valueMe.includes( elem ) );    

  // conctat me or others values to each array values
  let values = [];
  if( Array.isArray( valueMe ) && Array.isArray( valueOthers ) )
      values = [ ...valueMe.map( elem => elem + '|me' ), ...valueOthers.map( elem => elem + '|others' ) ];

  else if( !Array.isArray( valueMe ) && Array.isArray( valueOthers ) )
    values = valueOthers.map( elem => elem + '|others' );

  else if( Array.isArray( valueMe ) && !Array.isArray( valueOthers ) )
    values = [ ...valueMe.map( elem => elem + '|me' ) ];

  // render element
  root.render
  (    
    values.length > 0 ? 
      <Fragment>
        {
          values.slice( 0, maxPictoToDisp ).map( ( pictoLabel, index ) => 
            <span 
              key={ index } 
              title={ pictoLabel.split( '|' )[0] }
              className={ pictoLabel.split( '|' )[1] === 'me' ?  'highlight' : '' }
            >
              {
                getPicto( 
                  pictoLabel.split( '|' )[0], 
                  { 
                    size: pictoLabel.split( '|' )[1] === 'me' ?  '1.1rem' : '1.3rem', 
                    color: pictoLabel.split( '|' )[1] === 'me' ?  'white' : grey2Color 
                  } 
                )
              }
            </span> 
          )
        }

        { 
          values.length > maxPictoToDisp ? 
            <Provider store={ store }>
              <WindowButton
                id={ 'pictos-details-' + id }
                className="pictos-details"
                windowContent={ 
                  <Fragment>
                    { values.slice( maxPictoToDisp, values.length ).map( ( pictoLabel, index ) => 
                      <span 
                        key={ index } 
                        title={ pictoLabel.split( '|' )[0] }
                        className={ pictoLabel.split( '|' )[1] === 'me' ?  'highlight' : '' }
                      >
                        {
                          getPicto( 
                            pictoLabel.split( '|' )[0], 
                            { 
                              size: pictoLabel.split( '|' )[1] === 'me' ?  '1.1rem' : '1.3rem', 
                              color: pictoLabel.split( '|' )[1] === 'me' ?  'white' : grey2Color 
                            } 
                          )
                        }
                      </span> 
                    )}
                  </Fragment>
                }
                buttonContent={ '+' + ( values.length - maxPictoToDisp ) }
                mouseEventType="over"
                positionClassName={ Number.isInteger( ( index + 1 ) / pageSize ) ? 'bottom' : 'top' }
              />
            </Provider>
            : null
        }

      </Fragment>
      : value === null ?
        getPicto( 'Empty', { size: '1.2rem' } )
      : null
  );

  return rootElement;
} 

export default GridCellPictos;