/** Dependencies */
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'smart-webcomponents-react/chart';

/** Helpers */
import { toolTipFormatFunction } from './../../helpers/charts';
import { 
  getMinMaxFromArrayObject, 
  isNullOrUndefined 
} from './../../helpers/functions';

/** SCSS */
import './InsightsCompareMultiLineChart.scss';

function InsightsCompareMultiLineChart( props )
{
  const {
    id,
    datas,
    seriesGroups,
    series,
    dataField,
    flip,
    xAxisPosition,
    xAxisLabelVisible,
    mouseOverCallbackFct,
    highlightedValues,
    selectedSeriesGroup,
    padding
  } = props;

  // Define ref
  const chartRef = useRef( null );
  
  // Define positive and negitive colors
  const gridLinesColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey3' ).trim();

  // get min / max values from compare and current datas
  let minMaxValues = null;
  if( datas[0] )
    minMaxValues = getMinMaxFromArrayObject( datas, Object.keys( datas[0] ).filter( elem => elem !== 'xaxis' ) );

  /** Init State */
  const [ mouseOverDetails, setMouseOverDetails ] = useState( null );
  const [ chart, setChart ] = useState( null );

  // Define settings for chart
  const settings = {
    caption: '',
    description: '',
    showLegend: false,
    animation: 'none',
    showBorderLine: false,
    showToolTips: true,
    backgroundColor: 'white',
    columnSeriesOverlap: true,
    toolTipFormatFunction: ( value, itemIndex, serie, group, xAxisValue, xAxis ) => toolTipFormatFunction( value, itemIndex, serie, group, xAxisValue, xAxis, datas, 'keywords' ),
    enableCrosshairs: true,
    padding: !isNullOrUndefined( padding ) ? 
      padding 
      : {
        left: 0,
        top: 5,
        right: 0,
        bottom: 5
      },
    titlePadding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    dataSource: datas,
    xAxis: {
      dataField: dataField,
      position: xAxisPosition !== undefined ? xAxisPosition : 'bottom',
      tickMarks: {
        visible: false
      },
      labels: {
        visible: xAxisLabelVisible !== undefined ? xAxisLabelVisible : true,
        class: 'x-axis-labels',        
        offset: {
          y: flip?.valueAxis !== undefined ? -5 : 5
        }
      },
      gridLines: {
        color: gridLinesColor
      },
      valuesOnTicks: false
    },
    seriesGroups: !isNullOrUndefined( seriesGroups ) ? 
      seriesGroups 
      : [{
          type: 'line',
          valueAxis: {
            axisSize: 30,
            visible: true,
            minValue: 0,
            maxValue: minMaxValues?.global?.max !== undefined ? minMaxValues?.global?.max + 2 : 100,
            flip: flip?.valueAxis !== undefined ? flip.valueAxis : false,
            gridLines: {
              dashStyle: '4,4',
              color: gridLinesColor
            },
            tickMarks: {
              visible: false
            },
            labels: {
              class: 'values-axis-labels'
            }
          },
          series: series
        }
      ]
  };

  /** Init Chart */
  useEffect( () => 
  {
    if( chart === null )
      setChart(
        <Chart
          {...settings}
          ref={ chartRef }
          id = { id + '-chart' }
          onMouseOver={ e => 
            e?.detail?.event !== undefined ? 
              setMouseOverDetails({
                groupIndex: 0, 
                serieValue: e.detail.serie.dataField,
                itemIndex: e.detail.elementIndex
              }) 
              : null
          }
        />
      );

  }, []);

  /** Update data source with chart datas */
  useEffect(() => 
  {
    if( chartRef.current !== null )
      chartRef.current.dataSource = datas;

    if( chartRef.current !== null )
      chartRef.current.toolTipFormatFunction = ( value, itemIndex, serie, group, xAxisValue, xAxis ) => 
        toolTipFormatFunction( value, itemIndex, serie, group, xAxisValue, xAxis, datas, 'keywords' );

  }, [ datas ]);

  /** Show or Hide series with selected series group */
  useEffect(() =>
  {
    if( 
      !isNullOrUndefined( selectedSeriesGroup ) 
      && chartRef.current !== null 
    )
      Object.keys( selectedSeriesGroup ).forEach( ( value, index ) => 
      {
        if( selectedSeriesGroup[ value ] ){
          chartRef.current.showSerie( index, 0 );
          chartRef.current.showSerie( index, 1 )
        } else {
          chartRef.current.hideSerie( index, 0 );
          chartRef.current.hideSerie( index, 1 );
        }
      });

  }, [ selectedSeriesGroup ]);

  /** Update series groups of chart with seriesGroups values */
  useEffect(() => 
  {
    if( chartRef.current !== null )
      chartRef.current.seriesGroups = seriesGroups;

  }, [ seriesGroups ]);

  /** Update padding of chart with padding value */
  useEffect(() => 
  {
    if( chartRef.current !== null )
      chartRef.current.padding = padding;

  }, [ padding ]);

  /** Call mouseOver callback function when mouse over dot of chart */
  useEffect( () => 
  {
    if( typeof mouseOverCallbackFct === 'function ')
      mouseOverCallbackFct( mouseOverDetails );

  }, [ JSON.stringify( mouseOverDetails ) ]);

  /** Show tooltip with highlightedValues */
  useEffect( () => 
  {
    if( 
      highlightedValues !== undefined 
      && highlightedValues !== null
      && chartRef.current !== null
    ){
      chartRef.current.showToolTip(
        highlightedValues.groupIndex,
        series.findIndex( serie => serie.dataField === highlightedValues.serieValue ),
        highlightedValues.itemIndex
      );
    }
  }, [ JSON.stringify( highlightedValues ) ]);
  
  return(
    <div className="insights-line-chart">
      { chart }
    </div>
  )
}

export default InsightsCompareMultiLineChart;