/** Dependencies */
import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';

/** Helpers */
import { getPicto } from './../../../helpers/pictos';
import { isNullOrUndefined } from './../../../helpers/functions';

/** SCSS */
import './GridCellPicTextPicLink.scss';

function GridCellPicTextPicLink( props )
{
  const {
    text,
    textLink,
    prePic,
    prePicOptions,
    prePicLink,
    postPic,
    postPicOptions,
    postPicLink,
    postText,
    onClickFct
  } = props;

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'textlink-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );

  // render element
  root.render(
    <Fragment>
      
      <div className='textlink-container'>
        { // pre picture with or without link
          prePicLink !== undefined && prePic !== undefined ? 
            <a href={ prePicLink } className='pic-link' target='_blank' rel="noreferrer" title={ prePicLink }>{ getPicto( prePic, prePicOptions !== undefined ? prePicOptions : {} ) }</a>
          : prePic !== undefined ? 
            <span title={ !isNullOrUndefined( prePicOptions?.title ) ? prePicOptions?.title : '' }>{ getPicto( prePic, prePicOptions ) }</span>
          : null
        }

        { // text with or without link
          textLink !== undefined && text !== undefined ? 
            <a className='textlink-wrapper-text' href={ textLink } target='_blank' rel="noreferrer" title={ textLink }>{ text }</a>
          : text !== undefined && !isNullOrUndefined( onClickFct ) ? 
            <p className='textlink-wrapper-text' onClick={ onClickFct }>{ text }</p>
          : text !== undefined ? 
            <p className='textlink-wrapper-text'>{ text }</p>
          : null
        }
        
        { // post picture with or without link
          postPicLink !== undefined && postPic !== undefined ? 
            <a href={ postPicLink } className='pic-link' target='_blank' rel="noreferrer" title={ postPicLink }>{ getPicto( postPic, postPicOptions !== undefined ? postPicOptions : {} ) }</a>
          : postPic !== undefined ? 
            <span title={ !isNullOrUndefined( postPicOptions?.title ) ? postPicOptions?.title : '' }>{ getPicto( postPic, postPicOptions ) }</span>
          : null
        }

        { // post text
          !isNullOrUndefined( postText ) ? 
            <span className='post-text'>{ postText }</span> 
            : null 
        }
      </div>

    </Fragment>
  );

  return rootElement;
} 

export default GridCellPicTextPicLink;